import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from 'gatsby-link'
import { rhythm } from "../utils/typography"
import { Breadcrumb, Container, Row, Col, Button, Card } from "react-bootstrap"

class ProjectPageTemplate extends React.Component {
  render() {
    const project = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={project.frontmatter.title}
          description={project.frontmatter.description || project.excerpt}
        />
        <Container className='partner'>
        <Row>
            <Col md={{span:12}} sm={12} xs={12}>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/projects/">
                    Projects
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {project.frontmatter.title}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        <Card className='p-3 no-anim'>
          <Row>
            <Col md={3} sm={12} xs={12}>
              <Img className="mt-2 mb-2 mx-auto d-block thumbnail"  width="100%" fluid={project.frontmatter.image.childImageSharp.fluid} alt={project.frontmatter.title} />
            </Col>
            <Col md={9} sm={12} xs={12}>
              <h1
                style={{
                  marginBottom: 0,
                }}
              >
                {project.frontmatter.title}
              </h1>
              <hr /> 
              <div dangerouslySetInnerHTML={{ __html: project.html }} />
              <Row>
                <Col md={{ span: 4,}}>
                  <Button
                    block
                    variant="primary"
                    key={project.frontmatter.link}
                    href={project.frontmatter.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {project.frontmatter.linkName}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row>
              <Col md={6} sm={6} xs={6}>
                {previous && (
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to={"/clients/" + previous.fields.slug} rel="prev">
                        ← {previous.frontmatter.title}
                      </Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                )}
              </Col>
              <Col md={6} sm={6} xs={6}>
                {next && (
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to={"/clients/" + next.fields.slug} rel="next">
                        {next.frontmatter.title} →
                      </Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  )}
              </Col>
            </Row> */}
            </Card>
          </Container>
      </Layout>
    )
  }
}

export default ProjectPageTemplate

export const pageQuery = graphql`
  query ProjectPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        link
        linkName
        image {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
